import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Modal,
  PageHeader,
  Space,
  Table,
  Spin,
  message,
  Result,
} from "antd";
import {
  UserOutlined,
  EditOutlined,
  DeleteOutlined,
  IdcardOutlined,
  MailOutlined,
  PhoneOutlined,
  UsergroupDeleteOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchVehicleAssignedDrivers } from "../store/reducers/vehicleReducer";
import { useNavigate, useParams } from "react-router-dom";
import {
  assignVehicleToDriver,
  unassignVehicleFromDriverAPI,
} from "../store/reducers/vehicleReducer";
import toast from "react-hot-toast";

const AssignedDriverList = ({ id }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [loadingSpin, setLoadingSpinner] = useState(true);
  const [unassihnedDriverId, setUnassihnedDriverId] = useState(null);
  const [assignedDrivers, setAssignedDrivers] = useState([]);
  const authtoken = useSelector((state) => state?.persist?.auth?.token);
  const operator = useSelector(
    (state) =>
      state?.persist?.operator?.operatorProfile?.operator?.operatorOrganization,
  );
  let state = useSelector((state) => state);
  const activeVehicle = useSelector(
    (state) => state?.persist?.vehicle?.activeVehicle,
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [DriverListTableOpen, setDriverListTableOpen] = useState(false);
  const [isUnassignedModalOpen, setUnssignedModal] = useState(false);

  const assignVehicleToDriver1 = () => {
    if (operator?.type?.name === "Fatigue Management") {
      navigate(`/app/vehicles/assign-multiple-drivers/${id}`);
      return;
    }
    setDriverListTableOpen(true);
  };

  const handleCancel = () => {
    setUnssignedModal(false);
  };
  useEffect(() => {}, []);
  const unassignVehicleFromDriver = () => {
    setLoadingSpinner(true);
    const payload = {
      token: authtoken,
      vehicleid: id,
      driverId: unassihnedDriverId,
    };
    dispatch(unassignVehicleFromDriverAPI(payload)).then((result) => {
      if (result.payload.data.success === true) {
        toast("Successfully unassigned the vehicle to the driver");
        const payload = {
          vehicleId: id,
          token: authtoken,
        };
        setUnssignedModal(false);
        if (payload) {
          dispatch(fetchVehicleAssignedDrivers(payload)).then((response) => {
            setAssignedDrivers(response?.payload?.data?.data?.drivers);
            setLoadingSpinner(false);
          });
        }
      }
    });
  };
  const onClickUnAssignDriver = (id) => {
    setUnassihnedDriverId(id);
    setUnssignedModal(true);
    return (
      <>
        <Modal
          style={{ padding: 0 }}
          open={isUnassignedModalOpen}
          onCancel={handleCancel}
          footer={null}
        >
          <Result
            style={{ padding: 0 }}
            status="warning"
            title="Confirm unassign?"
            subTitle="Are you sure you want to unassign this driver?"
            extra={[
              <Button
                onClick={unassignVehicleFromDriver}
                type="danger"
                key="console"
              >
                UNASSIGN
              </Button>,
              <Button onClick={handleCancel}>CANCEL</Button>,
            ]}
          />
        </Modal>
      </>
    );
  };

  useEffect(() => {
    setLoadingSpinner(false);
    const payload = {
      vehicleId: id,
      token: authtoken,
    };
    if (payload) {
      dispatch(fetchVehicleAssignedDrivers(payload)).then((response) => {
        setAssignedDrivers(response?.payload?.data?.data?.drivers);
      });
    }
  }, []);

  const DriverListTable = () => {
    let fetchAllDrivers = useSelector(
      (state) => state?.persist?.driver?.drivers,
    );

    let activeDriver = [];

    if (activeVehicle.type === "Heavy Vehicle") {
      const filteredData = fetchAllDrivers?.filter(
        (driver) => driver?.inactive === false && driver?.driverType.id === 1,
      );
      filteredData?.map((driverItem) => activeDriver.push(driverItem));
    } else {
      const filteredData = fetchAllDrivers?.filter(
        (driver) => driver?.inactive === false,
      );

      filteredData?.map((driverItem) => activeDriver.push(driverItem));
    }

    const handleOk = () => {
      setDriverListTableOpen(false);
    };

    const handleCancel = () => {
      setDriverListTableOpen(false);
    };
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Phone No.",
        dataIndex: "phoneNumber",
        key: "phoneNumber",
      },
      {
        title: "License No.",
        dataIndex: "licenseNumber",
        key: "licenseNumber",
      },
      {
        title: "Type",
        dataIndex: "driverType",
        key: "driverType",
        render: (item) => {
          return <p>{item.name}</p>;
        },
      },
    ];

    return (
      <Modal
        open={DriverListTableOpen}
        title="Select Driver"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        width={1000}
      >
        {contextHolder}
        <Spin spinning={loadingSpin} size="large">
          <Table
            dataSource={activeDriver}
            scroll={{
              y: 400,
            }}
            size={"middle"}
            columns={columns}
            pagination={false}
            onRow={(record) => ({
              style: { cursor: "pointer" },
              onClick: (event) => {
                console.log("onRow onClick", event);
                const payload = {
                  token: authtoken,
                  vehicleid: state?.persist?.vehicle?.activeVehicle?.id,
                  // driverid: state?.persist?.vehicle?.activeDriver?.id,
                  driverid: record?.id,
                };

                setLoadingSpinner(true);
                dispatch(assignVehicleToDriver(payload)).then((result) => {
                  if (result.payload.data.success === true) {
                    messageApi?.open({
                      type: "success",
                      content: result.payload.data.data,
                      className: "custom-class",
                      style: {
                        mrginTop: "10vh",
                      },
                      duration: 2000,
                    });
                    setDriverListTableOpen(false);
                    setLoadingSpinner(false);
                    setTimeout(() => {
                      navigate(`/app/vehicles`);
                    }, 2000);
                  }
                  if (result.payload.data.success === false) {
                    messageApi?.open({
                      type: "warning",
                      content: result.payload.data.data,
                      className: "custom-class",
                      style: {
                        marginTop: "10vh",
                      },
                    });
                    setDriverListTableOpen(false);
                    setLoadingSpinner(false);
                  }
                });
              },
            })}
          ></Table>
        </Spin>
        {}
      </Modal>
    );
  };

  const columns = [
    {
      title: "Driver Name.",
      dataIndex: "name",
      key: "name",
      render: (item) => {
        const newName = item?.split(" ");
        for (let i = 0; i < newName?.length; i++) {
          newName[i] = newName[i][0]?.toUpperCase() + newName[i]?.substr(1);
        }
        return <p>{newName?.join(" ")}</p>;
      },
    },

    {
      title: "Contact Driver.",
      dataIndex: "contact",
      key: "contact",
      render: (_, record) => (
        <div>
          <p style={{ marginBottom: "2px" }}>
            <PhoneOutlined /> {record.phoneNumber}
          </p>
          <p>
            <MailOutlined /> {record.email}
          </p>
        </div>
      ),
    },
    {
      title: "License Number.",
      dataIndex: "license",
      key: "license",
      render: (_, record) => (
        <Space size="middle">
          <IdcardOutlined /> {record.licenseNumber}
        </Space>
      ),
    },
    {
      title: "License Type.",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <p>{record.driverType.name}</p>
        </Space>
      ),
    },
    {
      title: "Action.",
      key: "action",
      render: (_, record) => (
        <a onClick={() => onClickUnAssignDriver(record?.id)}>
          <UsergroupDeleteOutlined />
          Unassigned
        </a>
      ),
    },
  ];

  return (
    <div>
      <Spin spinning={loadingSpin} size="large">
        <Modal
          style={{ padding: 0 }}
          open={isUnassignedModalOpen}
          onCancel={handleCancel}
          footer={null}
        >
          <Result
            style={{ padding: 0 }}
            status="warning"
            title="Confirm Unassign?"
            subTitle="Are you sure you want to unassign this driver?"
            extra={[
              <Button
                onClick={() => unassignVehicleFromDriver()}
                type="danger"
                key="console"
              >
                UNASSIGN
              </Button>,
              <Button onClick={handleCancel}>CANCEL</Button>,
            ]}
          />
        </Modal>
        <Card
          Style={{ padding: 0, margin: 0 }}
          title={
            <span
              style={{
                fontSize: "16px",
                fontWeight: "normal",
                textAlign: "left",
                display: "flex",
                alignItems: "center",
              }}
            >
              Assigned Driver List
            </span>
          }
          size="small"
          extra={[
            operator?.type?.name === "Fatigue Management" ? (
              <Button
                // className="ant-btn-primary"
                onClick={() => assignVehicleToDriver1()}
                type="primary"
                block
              >
                ASSIGN DRIVERS
              </Button>
            ) : activeVehicle.assignedTo !== null ? (
              <p> </p>
            ) : (
              <Button
                onClick={() => assignVehicleToDriver1()}
                type="primary"
                block
              >
                ASSIGN A DRIVER
              </Button>
            ),
          ]}
        >
          <Table
            columns={columns}
            dataSource={assignedDrivers}
            style={{
              overflowY: "scroll",
              height: "32vh",
              position: "relative",
            }}
          />
        </Card>
        <DriverListTable />
      </Spin>
    </div>
  );
};
export default AssignedDriverList;

