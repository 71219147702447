import axios from "axios";

const dev = false;

const api_endpoint = dev
  ? "https://elogbackend-staging-production.up.railway.app/" // for pipeline/staging
  : `https://elogbackends-production.up.railway.app/`; // for pipeline/production

const API = axios.create({
  baseURL: api_endpoint,
});

export default API;
